<template>
  <div>
    <q-card class="bg-white">
      <q-card-section class="row">
        <q-list class="col-6">
          <q-item>
            <q-item-section avatar>
              <m-icons-avatar
                :type="booking.type === 'other' ? 'concierge' : booking.type"
                size="20px"
                class="type"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label label class="text-capitalize">
                {{ $t('booking.typeof', { type: $tc(`content_type.${request.content_type}`) }) }}
              </q-item-label>
              <q-item-label caption>
                {{ request.reference }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list v-if="request.organisation" class="col-6">
          <q-item>
            <q-item-section avatar>
              <q-avatar icon="business" color="blue" class="text-white" />
            </q-item-section>
            <q-item-section>
              <q-item-label label class="link text-capitalize">
                <router-link :to="`/organisations/${request.organisation.slug}`">
                  {{ request.organisation.name }}
                </router-link>
              </q-item-label>
              <q-item-label caption>
                {{ $tc('organisation.organisation') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list class="col-6">
          <q-item>
            <q-item-section avatar>
              <q-avatar>
                <q-img ratio="1" :src="request.requester.picture" />
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label label class="link text-capitalize">
                <router-link :to="request.organisation ? `/organisations/${request.organisation.slug}/members/${request.requester.username}` : `/individuals/${request.requester.username}`">
                  {{ request.requester.display_name }}
                </router-link>
              </q-item-label>
              <q-item-label v-if="booking.passenger_name && request.content_type === 'onwardtravel'" caption>
                {{ $t('requester') }}
              </q-item-label>
              <q-item-label v-else caption>
                {{ $t('requester') }} / {{ $tc('traveller') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list v-if="request.content_type === 'onwardtravel'" class="col-6">
          <q-item>
            <q-item-section avatar>
              <q-avatar icon="person" color="blue" class="text-white" />
            </q-item-section>
            <q-item-section>
              <q-item-label label class="link text-capitalize">
                {{ booking.passenger_name || $t('gdpr.removed') }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('passenger_name') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list v-if="request.contact_number" class="col-6">
          <q-item>
            <q-item-section avatar>
              <q-avatar icon="phone" color="blue" class="text-white" />
            </q-item-section>
            <q-item-section>
              <q-item-label label class="text-capitalize">
                {{ request.contact_number }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('contact.number') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list v-if="request.contact_email" class="col-6">
          <q-item>
            <q-item-section avatar>
              <q-avatar icon="email" color="blue" class="text-white" />
            </q-item-section>
            <q-item-section>
              <q-item-label label>
                {{ request.contact_email }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('contact.email') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list v-if="request.requester.display_name !== request.user.display_name" class="col-6">
          <q-item>
            <q-item-section avatar>
              <q-avatar>
                <q-img ratio="1" :src="request.user.picture" />
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label label class="link text-capitalize">
                <router-link :to="request.organisation ? `/organisations/${request.organisation.slug}/members/${request.user.username}` : `/individuals/${request.user.username}`">
                  {{ request.user.display_name }}
                </router-link>
              </q-item-label>
              <q-item-label caption>
                {{ $tc('traveller') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list class="col-6">
          <q-item>
            <q-item-section avatar>
              <q-avatar icon="done_outline" color="blue" class="text-white" />
            </q-item-section>
            <q-item-section>
              <q-item-label label class="text-capitalize">
                {{ booking.created_at.date | timezoneFilter('Europe/London') }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('created_at') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list class="col-6">
          <q-item>
            <q-item-section avatar>
              <q-avatar icon="attach_money" color="blue" class="text-white" />
            </q-item-section>
            <q-item-section>
              <q-item-label label class="text-capitalize">
                {{ booking.price.total ? booking.price.total.text : null }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('total_cost_including_fees') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>

      <q-card-actions>
        <q-btn flat label="Amend" color="primary" @click="() => showDialog('amendment')" />
        <q-btn flat label="Cancel" color="negative" @click="() => showDialog('cancellation')" />
      </q-card-actions>
    </q-card>

    <!-- Additional Information (NOTES) -->
    <m-group-title
      v-if="booking.notes.data[0]"
      :col="12"
      :label="$t('additional_information')"
      style="margin-top: 15px;"
    />
    <q-card v-if="booking.notes.data[0]" class="bg-white">
      <button
        v-if="booking.type === 'onwardtravel'"
        class="map-status-button"
        aria-label="See Taxi Status on Map"
        @click="seeOnMap"
      >
        See on Map
      </button>
      <div
        v-for="note in formattedNotes.filter(n => n.parent_id === null)"
        :key="note.reference"
      >
        <m-editor-viewer v-if="booking.notes.data.filter(n => n.parent_id === note.id)" :content="note.body" :style="booking.notes.data.length > 1 ? 'padding-bottom: 0' : ''" />
        <m-editor-viewer v-else :content="note.body" />
        <q-list v-if="booking.notes.data.filter(n => n.parent_id === note.id)">
          <q-item
            v-for="childNote in formattedNotes.filter(n => n.parent_id === note.id)"
            :key="childNote.id"
            class="bg-white"
            multiline
          >
            <q-item-section side top aria-hidden="true">
              &#9584;
            </q-item-section>
            <q-item-section>
              <q-item-label>
                <small>{{ childNote.body }}</small>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-card>
    <template v-if="booking.type === 'onwardtravel'">
      <div class="col-12 col-xl-6">
        <m-group-title
          class="title"
          label="Request Details"
          columns="null"
        />
        <q-card class="">
          <q-card-section>
            <div class="row q-col-gutter-sm">
              <div class="col-6">
                <q-item-label>
                  {{ $t('pick_up_location') }}
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.start_location || "N/A" : "N/A" }}
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.start_postcode || "N/A" : "N/A" }}
                </q-item-label>
              </div>
              <div class="col-6">
                <q-item-label>
                  {{ $t('drop_off_location') }}
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.end_location || "N/A" : "N/A" }}
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.end_postcode || "N/A" : "N/A" }}
                </q-item-label>
              </div>
              <div class="col-6">
                <q-item-label>
                  {{ $t('date.date_time_of_pick_up') }}
                </q-item-label>
                <q-item-label v-if="request && request.summary" caption>
                  {{ request.required_by.date | date }}
                </q-item-label>
                <q-item-label v-else caption>
                  N/A
                </q-item-label>
              </div>
              <div class="col-6">
                <q-item-label>
                  {{ $t('number_of.passengers') }}
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.number_of_passengers || "N/A" : "N/A" }}
                </q-item-label>
              </div>
              <div class="col-6">
                <q-item-label>
                  {{ $t('onwardtravel.taxi_company') }}
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.taxi_company || "N/A" : "N/A" }}
                </q-item-label>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-12 col-xl-6">
        <m-group-title
          class="title"
          label="Extra Passenger Details"
          columns="null"
        />
        <q-card class="">
          <q-card-section>
            <div class="row q-col-gutter-sm">
              <div class="col-4">
                <q-item-label>
                  Business Area
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.business_area || "N/A" : "N/A" }}
                </q-item-label>
                <q-item-label class="xl" caption>
                  <br>
                </q-item-label>
              </div>
              <div class="col-4">
                <q-item-label>
                  Cost Centre
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.cost_centre || "N/A" : "N/A" }}
                </q-item-label>
              </div>
              <div class="col-4">
                <q-item-label>
                  Distance in Miles
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.distance_in_miles || "N/A": "N/A" }}
                </q-item-label>
              </div>
              <div class="col-4">
                <q-item-label>
                  {{ $t('onwardtravel.customer_reference') }}
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.customer_reference || 'N/A' : "N/A" }}
                </q-item-label>
              </div>
              <div class="col-4">
                <q-item-label>
                  {{ $t('rac.vehicle.reg') }}
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.vehicle_registration || "N/A" : "N/A" }}
                </q-item-label>
              </div>
              <div class="col-4">
                <q-item-label>
                  {{ $t('rac.vehicle.type') }}
                </q-item-label>
                <q-item-label caption>
                  {{ request && request.summary ? request.summary.vehicle_type || "N/A" : "N/A" }}
                </q-item-label>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div v-if="request && request.summary.instructions_for_driver" class="col-12">
        <m-group-title
          class="title"
          label="Instructions to Driver"
          columns="null"
        />
        <q-card class="">
          <q-card-section>
            <div>{{ request.summary.instructions_for_driver || "N/A" }}</div>
          </q-card-section>
        </q-card>
      </div>
      <div v-else-if="booking.attributes.instructions">
        <m-group-title
          class="title"
          label="Instructions to Driver"
          columns="null"
        />
        <q-card class="">
          <q-card-section>
            <div>{{ booking.attributes.instructions || "N/A" }}</div>
          </q-card-section>
        </q-card>
      </div>
    </template>

    <!-- New Request Dialog -->
    <q-dialog v-model="showCancellationDialog">
      <q-card>
        <q-card-section>
          <q-item-label>
            {{ newRequest.type === 'amendment' ? 'An amendment' : 'A cancellation' }} request will be made against this booking and will need to be processed in the Back Office for the {{ newRequest.type }} to reflect against the booking.
            <br><br>Any extra information?
          </q-item-label>
        </q-card-section>
        <q-card-section>
          <form id="new-request" @submit.prevent="submitSDCRequest()">
            <label>Type <span>{{ newRequest.type }}</span></label>
            <label>
              Information
              <textarea v-model="newRequest.message" required />
            </label>
            <label>
              Contact Number
              <input v-model="newRequest.contact_number" type="tel">
            </label>
            <label>
              Contact Email
              <input v-model="newRequest.contact_email" type="email">
            </label>
          </form>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn v-close-popup flat :label="$t('cancel')" color="secondary" />
          <q-btn form="new-request" type="submit" flat :label="$t('confirm.confirm')" color="primary" :loading="sendingRequest" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import timezoneFilter from 'utils/timezoneFilter'
import date from 'utils/date-time'
import { MIconsAvatar, MGroupTitle, MEditorViewer } from 'components/'
import { pickBy } from 'lodash'
import { handleErrors } from 'utils/utils'
import { post as postRequest } from 'api/requests.js'

export default {
  components: { MIconsAvatar, MGroupTitle, MEditorViewer },
  filters: {
    timezoneFilter,
    date (val) {
      return date.toShortDateTime(val)
    }
  },
  props: ['request', 'booking'],
  data () {
    return {
      showCancellationDialog: false,
      newRequest: {
        type: null,
        message: null,
        contact_number: null,
        contact_email: null
      },
      sendingRequest: false
    }
  },
  computed: {
    formattedNotes () {
      return this.booking.notes.data.map(note => note)
    }
  },
  methods: {
    seeOnMap () {
      this.$router.push({ name: `bookings-status`, params: { ref: this.booking.reference } })
    },
    showDialog (type) {
      this.newRequest.type = type
      this.newRequest.message = `SDC ${type} request`
      this.newRequest.contact_number = this.booking.requester.data.meta.mobile
      this.newRequest.contact_email = this.booking.requester.data.email
      this.showCancellationDialog = true
      // Focus on the textarea once the form becomes visible
      this.$nextTick(() => {
        const form = document.getElementById('new-request')
        form.children[1].focus()
      })
    },
    async submitSDCRequest () {
      this.sendingRequest = true
      try {
        const body = {
          ...this.newRequest,
          content_type: this.booking.type,
          travel_type: this.booking.travel_type,
          booking: this.booking.reference,
          customer_reference: this.booking.reference,
          requester: this.booking.requester.data.username,
          user: this.booking.user.data.username
        }

        const response = await postRequest(pickBy(body))
        this.showCancellationDialog = false
        this.newRequest = {
          type: null,
          message: null,
          contact_number: null,
          contact_email: null
        }
        this.$router.push({
          name: 'view-request',
          params: {
            reference: response.data.reference
          }
        })
      } catch (err) {
        handleErrors(err)
      } finally {
        this.sendingRequest = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.type
  height 38px!important
  width 38px!important
  border-radius 30px
.grid
  @media (min-width 768px)
    display grid
    height 100%
    grid-template-columns 1fr 1fr

.link:hover
  text-decoration underline

.map-status-button
  position absolute
  right 12px
  top 12px
  background none
  border none
  color primary
  padding 0

form
  display flex
  flex-direction column
  row-gap 16px
  label
    display grid
    grid-template-columns 120px minmax(240px, auto)
    grid-column-gap 8px
    font-weight 600
    text-transform capitalize
    input, textarea
      font-weight initial
      margin-block-start -2px
      padding 4px
      border 1px solid #ddd
      border-radius 4px
      max-width 400px
      min-width 240px
</style>
