// define a list of components which are lazy loaded
module.exports = {
  MAttachLabelMenu: () => import('./MAttachLabelMenu'),
  MAttachToBookingRequest: () => import('./MAttachToBookingRequest'),
  MBanner: () => import('./MBanner'),
  MBookingCardList: () => import('./MBookingCardList'),
  MBookingComplete: () => import('./MBookingComplete'),
  MBottomSheet: () => import('./MBottomSheet'),
  MCardProvider: () => import('./MCardProvider'),
  MCheckbox: () => import('./MCheckbox'),
  MContentTypeEnable: () => import('./MContentTypeEnable'),
  MDashAppbar: () => import('./MDashAppbar'),
  MDashBookingCard: () => import('./MDashBookingCard'),
  MDateRangeSelect: () => import('./MDateRangeSelect'),
  MDefinedColourPicker: () => import('./MDefinedColourPicker'),
  MDiscoveryDetailModal: () => import('./MDiscoveryDetailModal'),
  MDropdownTransition: () => import('./MDropdownTransition'),
  MEditor: () => import('./MEditor'),
  MEditorViewer: () => import('./MEditorViewer'),
  MEmptyState: () => import('./MEmptyState'),
  MErrorBlock: () => import('./MErrorBlock'),
  MErrorDialog: () => import('./MErrorDialog'),
  MFieldColourPicker: () => import('./MFieldColourPicker'),
  MGmaps: () => import('./MGmaps'),
  MGmapsStatic: () => import('./MGmaps/Static'),
  MGroupTitle: () => import('./MGroupTitle'),
  MIconsAvatar: () => import('./MIcons/Avatar'),
  MIconsType: () => import('./MIcons/Type'),
  MImage: () => import('./MImage'),
  MImageModal: () => import('./MImageModal'),
  MIndexList: () => import('./MIndexList'),
  MInfiniteActivity: () => import('./MInfiniteActivity'),
  MInfiniteScroll: () => import('./MInfiniteScroll'),
  MInvoiceCustomiser: () => import('./MInvoiceCustomiser'),
  MInvoices: () => import('./MInvoices'),
  MJourneyCardList: () => import('./MJourneyCardList'),
  MLabel: () => import('./MLabel'),
  MLocationSearch: () => import('./MLocationSearch'),
  MMobileAppbar: () => import('./MMobileAppbar'),
  MMobileBackButton: () => import('./MMobileBackButton'),
  MMobileFooter: () => import('./MMobileFooter'),
  MMobilityNearbyCards: () => import('./MMobilityNearbyCards'),
  MMobilityNearbyDetails: () => import('./MMobilityNearbyDetails'),
  MModal: () => import('./MModal'),
  MMultiInput: () => import('./MMultiInput'),
  MCtaButton: () => import('./MCtaButton'),
  MPaypal: () => import('./MPaypal'),
  MResourceNotes: () => import('./MResourceNotes'),
  MResourceTableHead: () => import('./MResourceTableHead'),
  MResourceTopSheet: () => import('./MResourceTopSheet'),
  MSearch: () => import('./MSearch'),
  MSearchResultItem: () => import('./MSearchResultItem'),
  MSearchResultList: () => import('./MSearchResultList'),
  MSelectCountry: () => import('./MSelectCountry'),
  MSelectNationality: () => import('./MSelectNationality'),
  MSelectQuery: () => import('./MSelectQuery'),
  MTerms: () => import('./MTerms'),
  MTimetableCard: () => import('./MTimetableCard'),
  MTimetableRow: () => import('./MTimetableRow'),
  MTravelIcon: () => import('./MTravelIcon'),
  MUnifiedUsers: () => import('./MUnifiedUsers'),
  MUserMetaActions: () => import('./MUserMetaActions'),
  MVInfiniteScroll: () => import('./MVInfiniteScroll'),

  // MSpinners
  MSpinnerArrow: () => import('./MSpinner/Arrow'),
  MSpinnerGeo: () => import('./MSpinner/Geo'),
  MSpinnerKinto: () => import('./MSpinner/Kinto'),

  // MFields
  MPhoneField: () => import('./MFields/phone'),
  MTimeField: () => import('./MFields/time'),
  MDateField: () => import('./MFields/date.vue'),
  MDurationField: () => import('./MFields/duration.vue'),
  MMoneyField: () => import('./MFields/money.vue'),

  MContentTypeSelect: () => import('./MFields/content-type-select.vue'),
  MLabelFilterSelect: () => import('./MFields/label-filter-select.vue'),

  MBookingLookup: () => import('./MFields/booking-lookup.vue'),
  MOrgLookup: () => import('./MFields/org-lookup'),
  MUserLookup: () => import('./MFields/user-lookup.vue'),

  MGuestBlocker: () => import('./MGuestBlocker'),
  MLeaveNow: () => import('./MLeaveNow'),

  MCostCentre: () => import('./MCostCentre'),
  MBusinessArea: () => import('./MBusinessArea'),

  MBookingTimeline: () => import('./MBookingTimeline'),
  MBookingTimelineEntry: () => import('./MBookingTimeline/Entry'),

  MChangeRole: () => import('./MChangeRole')
}
