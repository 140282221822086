import _ from 'lodash'
import Vue from 'vue'
import date from 'utils/date-time'
import { details as lookup } from 'api/location'

import { search, routeInfo, additionalInfo } from 'api/ride-hailing'
import getPartnerFromHostname from 'utils/partner'
import { handleErrors } from 'utils/utils'

const getDetails = place => lookup(place).then(({ data: { address, lookup } }) => ({ address, lookup }))

const initial = {
  origin: null,
  destination: null,
  time: null,
  routeInfo: null,
  vehicleGroups: null,
  selectedVehicle: null,
  traveller: null,
  requester: null,
  passengers: 2,
  lastSearch: null,
  summary: null,

  journey: {
    name: null,
    reference: null
  },
  info: null
}

const state = _.cloneDeep(initial)

const getters = {
  origin: (state) => state.origin,
  destination: (state) => state.destination,
  originLabel: (state) => state.origin ? state.origin.label : null,
  destinationLabel: (state) => state.destination ? state.destination.label : null,
  time: (state) => state.time,
  formattedTime: (state) => date.toShortDateTime(state.time),
  routeInfo: (state) => state.routeInfo,
  vehicleGroups: (state) => state.vehicleGroups,
  selectedVehicle: (state) => state.selectedVehicle,
  passengers: state => state.passengers,

  searchParams (state) {
    const partner = getPartnerFromHostname().slug
    return state.origin && state.destination && {
      origin: state.origin.udprn || state.origin.place_id || state.origin.value,
      destination: state.destination.udprn || state.destination.place_id || state.destination.value,
      time: date.toCivilDateTime(state.time),
      user: state.traveller && state.traveller.value,
      requester: state.requester && state.requester.value,
      passengers: state.passengers,
      ...(partner === 'gohi' && { included_providers: ['C1'] })
    }
  },

  lastSearch: state => state.lastSearch,
  changesMade (state, getters) {
    return (state.origin && state.destination && state.lastSearch) && (state.lastSearch.origin !== getters.searchParams.origin ||
           state.lastSearch.destination !== getters.searchParams.destination ||
           state.lastSearch.time !== getters.searchParams.time)
  },
  summary: state => state.summary,

  traveller: state => state.traveller,
  requester: state => state.requester,
  journey: state => state.journey,
  info: state => state.info
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  setValue ({ commit }, { prop, payload }) {
    commit('setValue', { prop, payload })
  },
  async setLocation ({ commit }, { field, location }) {
    if (!location) {
      return commit('setValue', { prop: field, payload: null })
    }

    // If current location has no udprn or latlng, attempt to lookup
    if (!location.udprn || (!location.latlng?.lat || !location.latlng?.lng)) {
      try {
        const { address, lookup } = await getDetails(location.place_id || location.value)
        location.udprn = location.udprn || address.udprn
        // Populate other fields if not already set
        location.place_id = location.place_id || address.place_id
        location.value = location.value || lookup
        if (address.lat && address.lng) location.latlng = { lat: address.lat, lng: address.lng }
      } catch (e) {
        handleErrors(e, 'Unable to get location')
        return commit('setValue', { prop: field, payload: null })
      }
    }

    commit('setValue', { prop: field, payload: location })
  },
  setRequester ({ commit }, payload) {
    commit('setValue', { prop: 'requester', payload })
  },
  setTraveller ({ commit }, payload) {
    commit('setValue', { prop: 'traveller', payload })
  },
  setJourney ({ commit }, payload) {
    commit('setValue', { prop: 'journey', payload })
  },
  async search ({ commit, getters, dispatch, rootGetters }, isOffice) {
    commit('setValue', {
      prop: 'vehicleGroups',
      payload: null
    })
    try {
      const searchParams = {
        ...(isOffice ? getters.searchParams : rootGetters['ondemand/stash'].parameters),
        wheelchair_accessible: rootGetters['ondemand/stash'].wheechairRequired || false
      }

      const res = await search(_.pickBy(searchParams))

      commit('setValue', {
        prop: 'lastSearch',
        payload: getters.searchParams
      })

      let sortedGroups = []

      res.data.forEach(item => {
        if (item.category_label === 'Taxi') sortedGroups[0] = item
        else if (item.category_label === 'Exec') sortedGroups[1] = item
        else if (item.category_label === 'MPV') sortedGroups[2] = item
        else if (item.category_label === 'WAV') sortedGroups[3] = item
        else if (item.category_label === 'Other') sortedGroups[4] = item
        else sortedGroups.push(item)
      })

      sortedGroups = sortedGroups.filter(item => item !== null) // Remove empty indexes resulting from that category not returning

      sortedGroups.forEach(group => {
        group.options.sort((option1, option2) => option1.price.amount - option2.price.amount)
      })

      commit('setValue', {
        prop: 'vehicleGroups',
        payload: sortedGroups
      })
    } catch (e) {
      commit('setValue', {
        prop: 'vehicleGroups',
        payload: []
      })
      return Promise.reject(e)
    }
  },
  async getRouteInfo ({ commit }, points) {
    const res = await routeInfo(points)

    const info = res.data
    commit('setValue', {
      prop: 'routeInfo',
      payload: info
    })
  },
  async saveAdditionalInfo ({ commit, getters }, info) {
    const res = await additionalInfo(getters.selectedVehicle.token, info)

    commit('setValue', {
      prop: 'summary',
      payload: res.data
    })
  },
  saveLocations ({ getters, dispatch }) {
    dispatch('addresses/saveRecentLocations', [getters.origin, getters.destination], { root: true })
  }
}

const mutations = {
  reset (state) {
    const empty = _.cloneDeep(initial)
    for (let prop in empty) {
      Vue.set(state, prop, empty[prop])
    }
  },
  setValue (state, { prop, payload }) {
    Vue.set(state, prop, payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
