import _ from 'lodash'
import { details as lookup } from 'api/location'
import { handleErrors } from 'utils/utils'

const getDetails = place => lookup(place).then(({ data: { address, lookup } }) => ({ address, lookup }))

const initial = {
  stash: {},
  filters: {},
  activeFilters: {},
  booking_token: null,
  journey: {
    name: null,
    reference: null
  },
  booking: {
    reference: null
  },
  selectionList: [],
  selected: null,
  origin: null,
  originField: null,
  destination: null,
  destinationField: null,
  time: null,
  passengers: {},
  cards: [],
  info: null,
  wheelchairRequired: false
}

const state = _.cloneDeep(initial)

const getters = {
  stash: (state) => state.stash,
  filters: (state) => state.filters,
  activeFilters: (state) => state.activeFilters,
  bookingToken: (state) => state.booking_token,
  journey: (state) => state.journey,
  bookingReference: (state) => state.booking.reference,
  selectionList: state => state.selectionList,
  selected: state => state.selected,
  origin: state => state.origin,
  originField: state => state.originField,
  destination: state => state.destination,
  destinationField: state => state.destinationField,
  time: state => state.time,
  passengers: state => state.passengers,
  cards: state => state.cards,
  info: state => state.info,
  wheelchairRequired: state => state.wheelchairRequired
}

const actions = {
  stash: ({ commit }, payload) => {
    commit('stash', payload)
  },
  parameters: ({ commit }, payload) => {
    commit('parameters', payload)
  },
  stashProp: ({ commit }, { path, value }) => {
    commit('set', { path, value })
  },
  unstash: ({ commit }, payload) => commit('unstash', payload),
  booking_token: ({ commit }, value) => commit('set', {
    path: 'booking_token',
    value
  }),
  journey: ({ commit, state }, newJourney) => commit('journey', newJourney),
  filters: ({ commit, state }, newFilters) => commit('filters', newFilters),
  activeFilters: ({ commit, state }, newFilters) => commit('activeFilters', newFilters),
  booking_reference: ({ commit }, reference) => {
    commit('set', {
      path: 'booking',
      value: {
        reference
      }
    })
  },
  async setLocation ({ commit }, { field, location }) {
    if (!location) {
      commit(`${field}Field`, null)
      commit(field, null)
      return
    }

    // If current location has no udprn or latlng, attempt to lookup
    if (!location.udprn || (!location.latlng?.lat || !location.latlng?.lng)) {
      try {
        const { address, lookup } = await getDetails(location.place_id || location.value)
        location.udprn = location.udprn || address.udprn
        // Populate other fields if not already set
        location.place_id = location.place_id || address.place_id
        location.value = location.value || lookup
        if (address.lat && address.lng) location.latlng = { lat: address.lat, lng: address.lng }
      } catch (e) {
        handleErrors(e, 'Unable to get location')
        return commit('setValue', { prop: field, payload: null })
      }
    }

    commit(`${field}Field`, location)
    commit(field, location)
  }
}

const mutations = {
  journey (state, newJourney) {
    for (let f in state.journey) {
      state.journey[f] = newJourney[f]
    }
  },
  stash (state, newStash) {
    state.stash = { ...state.stash, ...newStash }
  },
  parameters (state, newParams) {
    state.stash.parameters = { ...state.stash.parameters, ...newParams }
  },
  filters (state, newFilters) {
    state.filters = newFilters
  },
  activeFilters (state, newFilters) {
    state.activeFilters = newFilters
  },
  origin (state, latLng) {
    state.origin = latLng
  },
  destination (state, latLng) {
    state.destination = latLng
  },
  originField (state, origin) {
    if (origin && !origin.context) {
      const { town, street, postcode } = origin
      if (origin.label === 'Unnamed Road') {
        if (town && postcode) origin.label = `${town}, ${postcode}`
        else if (postcode) origin.label = `${street}, ${postcode}`
      }
    }
    state.originField = origin
  },
  destinationField (state, destination) {
    if (destination && !destination.context) {
      const { town, street, postcode } = destination
      if (destination.label === 'Unnamed Road') {
        if (town && postcode) destination.label = `${town}, ${postcode}`
        else if (postcode) destination.label = `${street}, ${postcode}`
      }
    }
    state.destinationField = destination
  },
  unstash (state, { path }) {
    _.unset(state.stash, path)
  },
  set (state, { path, value }) {
    _.set(state, path, value)
  },
  unset (state, { path }) {
    _.unset(state, path)
  },
  clear (state) {
    for (let f in state) {
      state[f] = _.cloneDeep(initial[f])
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
